<template>
  <v-content justify="center">
    <v-row justify="center">
      <v-col class="text-center" cols="12" lg="6">
        <div v-if="this.$router.currentRoute.query.mode=='resetPassword'" class="headline">Cambio de Password</div>
        <div v-else class="headline">Validacion de Correo Electrónico</div>
        <div class="ma-4 text-center" justify="center">
          <v-divider></v-divider>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="9" md="6" class="text-center">
       
         <div >
          <p>
            Tu código de verificación es:
            <v-chip class="ma-2" color="primary" label>{{ this.$router.currentRoute.query.oobCode}}</v-chip>
          </p>
          <p v-if="!this.$router.currentRoute.query.mode=='resetPassword'" class="grey--text">{{texto}}</p>
        <div v-if="this.$router.currentRoute.query.mode=='resetPassword'">
           <v-row justify="center">
           <v-col cols="12" sm="6" md="6">
          <v-text-field
            label="Ingresa Tu Nuevo Password"
            outlined
            type="password"
            v-model="newPassword"
          ></v-text-field>
        </v-col>
</v-row>
          <v-btn color="success" @click="changePass()">Confirmar</v-btn>
          </div>
          <div v-else>
            
              <v-btn color="success" @click="verifyEmail()">Completar Validación</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      
    >
      {{ textSnackBar }}
      <v-btn
        color="success"
        text
    
      >
    
      </v-btn>
    </v-snackbar>
  </v-content>
</template>

<script>
import firebase from "firebase";
console.log(firebase);
const auth = firebase.auth();

export default {
  data() {
    return {
      texto: "Validando Correo...",
      infoMailData: {},
      snackbar: false,
      textSnackBar: '', 
      newPassword:""
    };
  },
  computed: {
    infoMail: {
      get() {
     
        //console.log(this.$router);

        this.infoMailData = this.$router.currentRoute.query;
        //    this.valida(this.$router.currentRoute.query);
      }
    }
  },
  methods: {
    verifyEmail() {
      var self=this;
      auth.languageCode = "es";

    auth
      .applyActionCode(this.$router.currentRoute.query.oobCode)
      .then(function(rest) {
        console.log(rest);
        console.log("email verificado  correctamente");
        self.texto = "Tu email ha sido verificado correctamente";
        self.textSnackBar= 'Tu email ha sido verificado correctamente';
        self.snackbar=true;
        auth.signOut().then(function() {
          setTimeout(function(){
   self.$router.push({ name: "login" });

          }, 2500 )
     
      });


      })
      .catch(function(error) {
        console.log(error);
        self.texto =
          "Ha ocurrido un error con la verificación de tu correo. Esto puede deberse a que el correo fue previamente validado. Por favor intenta ingresando con tu nombre de usuario y contraseña."+error.message;
          self.textSnackBar= self.texto;
          self.snackbar=true;

        //     this.$router.push("/login");
      });
    },
      changePass() {
        auth.languageCode = "es";
        var self=this;
      
        auth.confirmPasswordReset(this.$router.currentRoute.query.oobCode, this.newPassword).then(function(resp) {
      // Password reset has been confirmed and new password updated.
      self.textSnackBar="Tu password ha reseteado exitosamente...Estamos redirigiendote al login"
      self.snackbar=true;
      auth.signOut().then(function() {
      setTimeout(function(){
         self.$router.push({ name: "login" });
      }, 2500 )
      })
    }).catch(function(error) {
      self.textSnackBar="Se ha producido un error al cambiar tu password. Cominícate con el equipo de soporte de Rayo."+error.message
      self.newPassword="";
      self.snackbar=true;
      console.log(error)

    }); 
    }
  },
  mounted() {
    console.log("mounted");

    console.log(this.$router.currentRoute.query.oobCode);


  }
};
</script>